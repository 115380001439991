import React from "react"
import Layout from "../components/layout"
import Loadable from 'react-loadable'
import "../components/global.scss"
import "../components/Home/style.scss"

// import Homepage from "../components/Home"

const LoadableHome = Loadable({
  loader: () => import("../components/Home"),
  loading() {
    return <div></div>
  },
  delay: 300
})

const IndexPage = () => (
  <Layout>
    <LoadableHome/>     
  </Layout>
)


export default IndexPage
